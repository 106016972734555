@use "setting" as *;

/* ======================================================================
 p-top
====================================================================== */

.u_sub_ttl-1 {
  font-weight: 800;
  font-size: 22px;
  line-height: calc(34 / 22);

  @include sp {
    font-size: 18px;
    line-height: calc(30 / 18);
  }
}


.l_content {
  z-index: -2;
}

/* ===========================================
* 感染症お知らせ
* ======================================== */
.mv_topic {
  position: absolute;
  right: -5px;
  bottom: 20px;
  z-index: 20;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 270px;
  border: 1px solid $color_15;
  border-radius: 6px 0 0 6px;
  background: $white;
  transition: opacity .3s;

  @include sp {
    position: relative;
    right: inherit;
    bottom: inherit;
    z-index: 30;
    width: 345px;
    border-radius: 6px;
    box-shadow: 0 3px 1px 0 rgba(186, 165, 113, .36);
  }
  .topic_txt {
    position: relative;
    padding: 8px 0 8px 15px;

    @include sp {
      padding: 11px 0 11px 25px;
    }
    &::before {
      position: absolute;
      top: 20px;
      left: 4px;
      width: 33px;
      height: 30px;
      background: url(#{$img_dir}/p-top/mv-info-icon.svg)no-repeat;
      content: "";
      transform: translate(-100%, 0);

      @include sp {
        top: 10px;
        left: 20px;
        width: 30px;
        height: 27px;
        background: url(#{$img_dir}/p-top/sp/mv-info-icon.svg)no-repeat;
      }
    }
    &::after {
      position: absolute;
      top: 50%;
      right: -25px;
      width: 7px;
      height: 11px;
      background: url(#{$img_dir}/p-top/mv-info-arw.svg)no-repeat;
      content: "";
      transform: translate(0, -50%);

      @include sp {
        display: none;
      }
    }
  }
  &:hover {
    @include pc_tab {
      background: rgba(255, 255, 255, .9);
      transition: all .3s;
    }
  }
}
.mv_topic_sp {
  margin: 30px auto 0;
  width: 345px;
}

/* ===========================================
* sec_01
* ======================================== */
.sec_01 {
  position: relative;
  margin: 30px 0 0;
  background-color: $color_6;

  @include sp {
    margin: 20px 0 0;
    padding: 0 0 30px;
  }
  .sec_container {
    position: relative;
    z-index: 30;
    margin: 0 auto;
    width: 1120px;

    @include sp {
      width: 345px;
    }
  }
  .sec_ttl_news {
    @include sp {
      width: 100%;
      text-align: center;
    }
  }
  .news_wrapper {
    padding: 30px 50px;
    border-radius: 20px;
    background-color: $color_33;

    @include sp {
      padding: 20px 15px;
      border-radius: 6px;
    }
  }
  .news_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include sp {
      flex-direction: column;
      width: 303px;
    }
  }
  .box_main {
    // width: 231px;
    @include sp {
      width: 100%;
    }
  }
  .btn_news {
    margin: 15px 0 0;
    width: 204px;
    height: 44px;

    @include sp {
      margin: 15px auto 0;
      width: 280px;
      height: 50px;
    }
  }
  .list {
    width: 780px;

    @include sp {
      margin: 15px auto 0;
      width: 315px;
    }
  }
  .item {
    display: flex;
    align-items: center;
    padding: 11px 0;
    border-bottom: 1px dotted $color_18;

    @include sp {
      align-items: inherit;
      flex-direction: column;
    }
  }
  .item:first-of-type {
    padding: 0 0 11px;
  }
  .item:last-of-type {
    padding: 11px 0 0;
    border-bottom: none;
  }

  .item_head {
    display: flex;
    align-items: center;

    @include sp {
    }
    .news_date {
      @include sp {
      }
    }
    .category {
      margin: 0 0 0 10px;
    }
  }
  .item_contents {
    display: block;
    margin: 0 0 0 18px;
    transition: all .3s;

    @include sp {
      margin: 0;
      width: auto;
    }
    &:hover {
      @include pc_tab {
        color: $color_23;
        text-decoration: underline $color_23;
        transition: all .3s;
      }
    }
  }
}

/* ===========================================
* sec_02
* ======================================== */
.sec_02 {
  position: relative;
  overflow-x: hidden;
  background-color: $color_6;

  @include sp {
    margin: -15px 0 0;
  }

  &::before {
    position: absolute;
    top: 70px;
    left: calc(100% * (516 / 1366));
    padding-top: min( calc(100% * (462 / 1366)),551px ) ;
    max-width: 969px;
    width: calc(100% * (812 / 1366));
    height: 0;
    border-radius: 0 20px 20px 0;
    background: linear-gradient(to right, $color_6,  transparent 420px),  url(#{$img_dir}/p-top/sec-02-top.png) no-repeat center / cover;
    content: "";

    @include pc_large {
      top: 80px;
      right: 0;
      right: calc(100% * (160 / 1920));
      left: inherit;
    }
    @include sp {
      position: absolute;
      top: 10px;
      left: calc(50% + 55px);
      z-index: 0;
      width: 141px;
      height: 141px;
      background: url(../img/p-top/sec-02-top-obj.png) no-repeat;
      content: "";
    }
  }
  &::after {
    position: absolute;
    top: 40px;
    left: -225px;
    z-index: 5;
    width: 750px;
    height: 542px;
    background-image: url(#{$img_dir}/p-top/sec-02-bg_01.svg);
    content: "";

    @include pc_large {
      left: 1.302%;
    }
    @include sp {
      top: 40px;
      right: -90px;
      left: inherit;
      z-index: 5;
      width: 750px;
      height: 542px;
    }
  }
  .sec_container {
    position: relative;
    z-index: 10;
    margin: 0 auto;
    padding: 125px 0 80px;
    width: 1152px;

    @include sp {
      margin: 0 auto;
      padding: 140px 0 50px;
      width: 345px;
    }
    &::before {
      position: absolute;
      top: 40px;
      left: calc(50% + 55px);
      width: 141px;
      height: 141px;
      background: url(#{$img_dir}/p-top/sec-02-top-obj.png) no-repeat;
      content: "";

      @include sp {
        display: none;
      }
    }
  }
  .info_sec_ttl_wrapper {
    position: relative;
    z-index: 10;

    @include sp {
      margin: 0 auto;
    }
  }
  .sec_ttl_info {
    position: relative;
    margin: 0 0 0 80px;

    @include sp {
      margin: 0 auto;
    }
    &::before {
      position: absolute;
      top: 8px;
      left: -87px;
      width: 74px;
      height: 67px;
      background-image: url(#{$img_dir}/p-top/sec-02-ttl.svg);
      background-size: cover;
      background-repeat: no-repeat;
      content: "";

      @include sp {
        top: -70px;
        left: 79px;
        width: 63px;
        height: 58px;
        background-image: url(#{$img_dir}/p-top/sp/sec-02-ttl.svg);
      }
    }
    &::after {
      position: absolute;
      content: "";

      @include sp {
      }
    }
  }
  .sec_lead {
    position: relative;
    z-index: 10;
    margin: 50px 0 0 15px;

    @include sp {
      margin: 35px 0 0;
      letter-spacing: .6px;
    }
  }
  .sec_txt_01 {
    position: relative;
    z-index: 10;
    margin: 20px 0 0 15px;
    width: 537px;

    @include sp {
      margin: 20px 0 0;
      width: 100%;
    }
  }

  .info_wrapper {
    margin: 40px 0 0 30px;

    @include sp {
      flex-direction: column;
      margin: 40px 0 0 0;
    }
  }
  .info_list {
    display: flex;

    @include sp {
      flex-direction: column;
    }
  }
  .info_list + .info_list {
    margin: 30px 0 0;

    @include sp {
      margin: 20px auto 0;
    }
  }
  .info_item {
    position: relative;
    z-index: 10;
    width: 900px;
    border-radius: 15px;
    transform: all .3s;
    pointer-events: none;

    @include sp {
      width: 345px;
      border-radius: 8px;
    }
    &::before {
      position: absolute;
      top: 8px;
      left: 8px;
      width: 545px;
      height: 100%;
      border-radius: 15px;
      background: $color_20;
      content: "";
      opacity: .53;
      transition: all .3s;
      pointer-events: none;

      @include sp {
        top: 7px;
        left: 6px;
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
    &:hover {
      &::before {
        top: 6px;
        left: 6px;
        transition: all .3s;
      }
    }
  }

  .item_inner {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    padding: 30px;
    border-radius: 15px;
    background-color: $white;
    transition: all .3s;
    pointer-events: auto;

    @include sp {
      padding: 15px;
      border-radius: 8px;
    }
    &:hover {
      @include pc_tab {
        top: 4px;
        left: 3px;
        background: #fbf9f6;
        transition: all .3s;
        .sub_ttl_info {
          span:before {
            width: 109%;
          }
        }
        .sub_ttl_info::after {
          left: calc(100% + 9px);
          transition: all .3s;
        }
      }
    }
  }
  .info_item_02 {
    position: relative;
    &::before {
      height: calc(100% - 80px);

      @include sp {
        height: 100%;
      }
    }

    &::after {
      position: absolute;
      right: -72px;
      bottom: 12px;
      width: 119px;
      height: 128px;
      background: url(#{$img_dir}/p-top/info-obj-01.svg);
      content: "";

      @include sp {
        display: none;
      }
    }
  }
  .info_item_03 {
    position: relative;
    &::after {
      position: absolute;
      bottom: 56px;
      left: -100px;
      width: 141px;
      height: 141px;
      background: url(#{$img_dir}/p-top/info-obj-04.svg);
      content: "";

      @include sp {
      }
    }
  }
  .info_item_04 {
    position: relative;
    &::before {
      height: calc(100% - 140px);

      @include sp {
        height: 100%;
      }
    }
    &::after {
      position: absolute;
      right: 48px;
      bottom: -13px;
      z-index: -1;
      width: 236px;
      height: 236px;
      background-image: url(#{$img_dir}/p-top/info-obj-03.svg);
      content: "";

      @include sp {
        right: inherit;
        bottom: -48px;
        left: -50px;
        width: 142px;
        height: 142px;
        background-image: url(#{$img_dir}/p-top/sp/info-obj-03.svg);
      }
    }
  }

  .info_item + .info_item {
    margin: -80px 0 0 30px;

    @include sp {
      margin: 20px 0 0;
    }
  }
  .info_head {
    display: flex;

    @include sp {
    }
  }
  .pic_info {
    width: fit-content;

    @include sp {
    }
    .img {
      @include sp {
        width: 150px;
        height: 101px;
      }
    }
  }
  .sub_ttl_info {
    position: relative;
    margin: 80px 0 0 57px;

    @include sp {
      margin: 70px 0 0 30px;
    }
    span::before {
      position: absolute;
      bottom: 30px;
      left: -5px;
      z-index: -1;
      width: 0;
      height: 15px;
      border-radius: 4px;
      background: linear-gradient(to top, transparent 0, $color_34 0, $color_34 15px, transparent 15px);
      content: "";
      transition: width .3s ease-in-out;
    }
    &.sub_ttl_info_01 {
      &::before {
        position: absolute;
        top: -3px;
        left: calc(50% + 13px);
        width: 61px;
        height: 59px;
        background-image: url(#{$img_dir}/p-top/info-01-icon.svg);
        background-size: cover;
        background-repeat: no-repeat;
        content: "";
        transform: translate(-50%, -100%);

        @include sp {
          width: 51px;
          height: 49px;
          background-image: url(#{$img_dir}/p-top/sp/info-01-icon.png);
        }
      }
    }
    &.sub_ttl_info_03 {
      &::before {
        position: absolute;
        top: -3px;
        left: calc(50% + 13px);
        width: 65px;
        height: 59px;
        background-image: url(#{$img_dir}/p-top/info-02-icon.svg);
        background-size: cover;
        background-repeat: no-repeat;
        content: "";
        transform: translate(-50%, -100%);

        @include sp {
          left: 54%;
          width: 53px;
          height: 48px;
          background-image: url(#{$img_dir}/p-top/sp/info-02-icon.png);
        }
      }
    }
    &.sub_ttl_info_02 {
      @include sp {
        margin: 70px 0 0 20px;
      }
      &::before {
        position: absolute;
        top: -3px;
        left: calc(50% + 13px);
        width: 83px;
        height: 59px;
        background-image: url(#{$img_dir}/p-top/info-03-icon.svg);
        background-size: cover;
        background-repeat: no-repeat;
        content: "";
        transform: translate(-50%, -100%);

        @include sp {
          left: 54%;
          width: 65px;
          height: 46px;
          background-image: url(#{$img_dir}/p-top/sp/info-03-icon.png);
        }
      }
    }
    &.sub_ttl_info_04 {
      &::before {
        position: absolute;
        top: -3px;
        left: calc(50% + 13px);
        width: 68px;
        height: 60px;
        background: url(#{$img_dir}/p-top/info-04-icon.svg) no-repeat;
        background-size: cover;
        content: "";
        transform: translate(-50%, -100%);

        @include sp {
          left: 60%;
          width: 57px;
          height: 50px;
          background-image: url(#{$img_dir}/p-top/sp/info-04-icon.png);
        }
      }
    }
    &::after {
      position: absolute;
      top: 0;
      left: calc(100% + 6px);
      width: 18px;
      height: 18px;
      background-image: url(#{$img_dir}/common/icon_arw.svg);
      background-size: cover;
      background-repeat: no-repeat;
      content: "";
      transition: all .3s;
      transform: translate(0, 50%);

      @include sp {
      }
    }
  }
  .info_lead {
    margin: 20px 0 0;
  }
  .info_txt_01 {
    margin: 15px 0 0;

    @include sp {
    }
  }
}

/* ===========================================
* sec_03
* ======================================== */
.sec_03 {
  background-color: $color_9;

  @include sp {
    background-color: $white;
  }
  .sec_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 130px 0 52px;
    width: 1240px;
    border-radius: 20px;
    background-color: $white;

    @include sp {
      padding: 100px 15px 52px;
      width: 375px;
    }
    @include sp_small {
      margin: 0 auto;
      padding: 130px 0 52px;
      width: 345px;
    }
  }
  .sec_inner {
    @include sp {
      width: 100%;
    }
  }
  .sec_ttl_clinic {
    @include sp {
    }
    &::before {
      top: -5px;
    }
    &::before {
      left: 50%;
      background-image: url(#{$img_dir}/p-top/sec-03-ttl.svg);

      @include sp {
        width: 64px;
        height: 52px;
        background-image: url(#{$img_dir}/p-top/sp/sec-03-ttl.svg);
      }
    }
  }
  .sec_lead {
    margin: 50px auto 0;
    width: fit-content;

    @include sp {
      margin: 35px auto 0;
    }
  }

  .contents_wrapper {
    display: flex;
    margin: 40px auto 0;

    @include sp {
      flex-direction: column;
      margin: 30px auto 0;
    }
  }
  .credit_wrapper {
    margin: 50px auto 0;

    @include sp {
      margin: 40px auto 0;
    }
  }

  .box_01 {
    @include sp {
    }
  }
  .box_02 {
    margin: 0 0 0 60px;

    @include sp {
      margin: 30px auto 0;
    }
  }

  .sub_ttl_clinic {
    position: relative;
    margin: 0 0 0 25px;
    width: fit-content;

    @include sp {
      margin: 0 0 0 25px;
    }
    &::before {
      position: absolute;
      top: 0;
      left: -10px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: $color_21;
      content: "";
      transform: translate(-100%, 100%);

      @include sp {
        top: 9px;
        transform: translate(-100%, 0);
      }
    }
  }
  .pic_time {
    margin: 20px 0 0;

    @include sp {
      margin: 20px auto 0;
    }
    .img {
      width: fit-content;

      @include sp {
        width: 345px;
        height: 134px;
      }
    }
  }
  .time_note {
    position: relative;
    margin: 10px 0 0 15px;
    padding: 0 0 0 6px;
    width: 410px;

    @include sp {
      width: 324px;
    }
    &:first-of-type {
      margin: 15px 0 0 15px;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "※";
      font-size: 16px;
      transform: translate(-100%, 0);

      @include sp {
        font-size: 15px;
      }
    }
    &:last-of-type {
      &::before {
        top: 55%;
        width: 16px;
        height: 16px;
        background: url(#{$img_dir}/p-top/clinic-star.svg)no-repeat;
        content: "";
        transform: translate(-100%, -50%);

        @include sp {
          background: url(#{$img_dir}/p-top/sp/clinic-star.svg)no-repeat;
        }
      }
    }
  }
  .small_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0 0;
    padding: 18px 0 19px;
    width: 422px;
    border-radius: 31px;
    background-color: $color_22;

    @include sp {
      padding: 12px 0 13px;
      width: 345px;
      border-radius: 25px;
    }
  }
  .small_box_txt {
    @include sp {
    }
  }
  .map_wrapper {
    margin: 20px 0 0;
    width: fit-content;
    iframe {
      width: 638px;
      height: 290px;

      @include sp {
        width: 345px;
        height: 220px;
      }
    }
  }
  .map_txt_wrapper {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0;

    @include sp {
      margin: 15px 0 0;
    }
  }

  .credit_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px auto 0;
    padding: 30px 0;
    width: 922px;
    border-radius: 15px;
    background-color: $color_9;

    @include sp {
      margin: 20px auto 0;
      padding: 20px 0;
      width: 345px;
      border-radius: 8px;
    }
  }
  .credit_box_inner {
    display: flex;

    @include sp {
      flex-direction: column;
    }
  }
  .credit_txt {
    @include sp {
      margin: 0 auto;
      width: 315px;
    }
  }
  .pic_credit {
    margin: 0 0 0 20px;

    @include sp {
      margin: 15px 0 0;
    }
  }
  .btn_access {
    margin: 50px auto 0;

    @include sp {
      margin: 30px auto 0;
      width: 280px;
      height: 50px;
    }
  }
}

/* ===========================================
* sec_04
* ======================================== */
.sec_04 {
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding: 230px 0 240px;
  background-color: $color_6;

  @include sp {
    padding: 60px 0 0;
  }
  .sec_container {
    position: relative;
    margin: 0 auto;
    width: 1120px;

    @include sp {
      overflow-x: hidden;
      padding: 100px 15px 70px 15px;
      width: 100%;
    }
    &::before {
      position: absolute;
      top: -130px;
      left: -220px;
      z-index: -1;
      width: 1481px;
      height: 915px;
      background: url(#{$img_dir}/p-top/sec-04-bg.png) no-repeat;
      content: "";

      @include sp {
        top: 4px;
        left: calc(50% - 350px);
        width: 903px;
        height: 660px;
        background-image: url(#{$img_dir}/p-top/sp/sec-04-bg.png);
        background-size: cover;
      }
    }
    .sec_ttl_wrapper_idea {
      margin: 0 0 0 70px;

      @include sp {
        margin: 0;
      }
    }
    .sec_ttl_idea {
      &::before {
        background-image: url(#{$img_dir}/p-top/sec-04-ttl.svg);

        @include sp {
          width: 69px;
          height: 60px;
          background-image: url(#{$img_dir}/p-top/sp/sec-04-ttl.svg);
        }
      }
    }
    .idea_ttl {
      margin: 0 0 0 63px;

      @include sp {
        margin: 0;
      }
    }
    .sec_lead {
      margin: 50px 0 0;
      width: fit-content;

      @include sp {
        margin: 70px auto 0;
      }
    }
    .btn_detail {
      position: relative;
      top: 0;
      left: 0;
      margin: 30px 0 0;
      width: 336px;
      height: 60px;

      @include sp {
        margin: 30px auto 0;
        width: 280px;
        height: 50px;
      }
    }
  }
}

/* ===========================================
* sec_05
* ======================================== */
.sec_05 {
  position: relative;
  background-color: $color_6;

  @include sp {
    padding: 90px 0 0;
  }
  &::before {
    @include sp {
      position: absolute;
      right: 0;
      bottom: 15px;
      width: 92px;
      height: 99px;
      background-image: url(#{$img_dir}/p-top/sp/feature-obj-02.svg);
      content: "";
    }
  }
  .sec_container {
    position: relative;
    z-index: 10;
    margin: 0 auto;
    padding: 0 0 100px;
    width: 1120px;

    @include sp {
      margin: 0 auto;
      padding: 0 0 60px;
      width: 345px;
    }
  }
  .sec_ttl_wrapper_feature {
    margin: 0 auto;
    &::before {
      left: 50%;
      background-image: url(#{$img_dir}/p-top/sec-05-ttl.svg);

      @include sp {
        left: 56%;
        background-image: url(#{$img_dir}/p-top/sp/sec-05-ttl.svg);
      }
    }
  }
  .sec_lead {
    margin: 45px auto 0;

    @include sp {
      margin: 45px auto 0;
    }
  }
  .sec_txt_01 {
    margin: 20px auto 0;
  }

  .feature_list {
    margin: 60px auto 0;

    @include sp {
      margin: 30px auto 0;
    }
  }
  .feature_item {
    display: flex;
    align-items: center;

    @include sp {
      align-items: inherit;
      flex-direction: column;
      justify-content: center;
    }
  }
  .feature_item:nth-of-type(even) {
    flex-direction: row-reverse;

    @include sp {
      flex-direction: column;
    }
    .feature_contents {
      margin: 0 -80px 0 0;

      @include sp {
        margin: -50px 0 0;
      }
    }
  }
  .feature_item:first-of-type {
    position: relative;
    &::before {
      position: absolute;
      bottom: -230px;
      left: -190px;
      z-index: -1;
      width: 510px;
      height: 351px;
      background-image: url(#{$img_dir}/p-top/feature-obj-03.svg);
      content: "";

      @include sp {
        display: none;
      }
    }
    .feature_contents {
      position: relative;

      &::before {
        position: absolute;
        top: 74%;
        left: 100%;
        z-index: -1;
        width: 119px;
        height: 128px;
        background-image: url(#{$img_dir}/p-top/feature-obj-01.svg);
        background-size: cover;
        background-repeat: no-repeat;
        content: "";
        transform: translate(-50%, 0);

        @include sp {
          top: 82%;
          left: 93%;
          z-index: 0;
          width: 76px;
          height: 93px;
          background-image: url(#{$img_dir}/p-top/sp/feature-obj-01.svg);
        }
      }
    }
  }
  .feature_item:nth-of-type(2) {
    @include sp {
      position: relative;
      &::before {
        position: absolute;
        bottom: -110px;
        left: -65px;
        z-index: -1;
        width: 280px;
        height: 235px;
        background: url(#{$img_dir}/p-top/sp/feature-obj-03.svg)no-repeat;
        content: "";
      }
    }
  }
  .feature_item:last-of-type {
    .feature_contents {
      position: relative;

      &::before {
        position: absolute;
        top: -55px;
        left: 100%;
        z-index: -1;
        width: 166px;
        height: 169px;
        background-image: url(#{$img_dir}/p-top/feature-obj-02.svg);
        background-size: cover;
        background-repeat: no-repeat;
        content: "";
        transform: translate(-50%, 0);

        @include sp {
          display: none;
        }
      }
    }
  }
  .pic_feature {
    margin: 0 0 0 -15px;
    width: fit-content;
    height: auto;

    @include sp {
    }
    .img {
      width: 650px;

      @include sp {
        width: 375px;
      }
    }
  }
  .feature_contents {
    margin: 0 0 0 -80px;
    padding: 50px;
    width: 550px;
    border-radius: 30px;
    background-color: $white;

    @include sp {
      margin: -50px 0 0;
      padding: 20px;
      width: 345px;
      border-radius: 10px;
    }
  }
  .sub_ttl_feature {
    position: relative;
    padding: 0 0 0 20px;

    @include sp {
      padding: 0 0 0 16px;
    }
    &::before {
      position: absolute;
      top: 5px;
      left: 0;
      width: 5px;
      height: 64px;
      border-radius: 6px;
      background-color: $color_19;
      content: "";
      transform: translate(-50%, 0);

      @include sp {
        height: 52px;
      }
    }
  }
  .feature_txt_01 {
    margin: 20px 0 0;

    @include sp {
      margin: 15px 0 0;
    }
  }
  .feature_item + .feature_item {
    margin: 40px auto 0;

    @include sp {
    }
  }
}

/* ===========================================
* sec_06
* ======================================== */
.sec_06 {
  background-color: $color_6;

  @include sp {
    background-color: $white;
  }
  .sec_container {
    margin: 0 auto;
    padding: 120px 0 65px;
    width: 1240px;
    border-radius: 20px;
    background-color: $white;

    @include sp {
      margin: 0 auto;
      padding: 95px 0 55px;
      width: 345px;
    }
  }
  .sec_ttl_wrapper_column {
    &::before {
      left: 50%;
      background-image: url(#{$img_dir}/p-top/sec-06-ttl.svg);

      @include sp {
        background-image: url(#{$img_dir}/p-top/sp/sec-06-ttl.svg);
      }
    }
  }

  .sec_lead {
    margin: 50px auto 0;

    @include sp {
      margin: 40px auto 0;
    }
  }

  .col_list {
    display: flex;
    margin: 80px auto 0;
    width: 1120px;

    @include sp {
      margin-top: 50px;
      margin-left: calc((345px - 100vw)/2);
      width: 100vw;
    }
  }
  .col_item {
    width: 350px;

    @include sp {
      margin: 0 0 0 20px;
      width: 260px;
    }
  }
  .col_item + .col_item {
    margin: 0 0 0 35px;

    @include sp {
      margin: 0 0 0 20px;
    }
  }
  .col_link {
    display: block;
    overflow: hidden;
    transition: all .3s;
    &:hover {
      @include pc_tab {
        opacity: .7;
        .col_pic {
          border-radius: 10px;
          .img {
            overflow: hidden;
            transition: all .3s;
            transform: scale(1.1,1.1);
          }
        }
      }
    }
  }
  .col_pic {
    overflow: hidden;
    width: 350px;
    height: 220px;
    border-radius: 10px;

    @include sp {
      margin: 0 auto;
      width: 260px;
      height: 163px;
    }
    .img {
      overflow: hidden;
      transition: all .3s;

      @include sp {
        width: 260px;
        height: 163px;
        border-radius: 10px;
      }
    }
  }
  .col_ttl {
    margin: 20px 0 0;

    @include sp {
      margin: 15px auto 0;
      width: 260px;
    }
  }
  .col_txt {
    margin: 10px 0 0;
    width: 350px;

    @include sp {
      margin: 10px auto 0;
      width: 260px;
    }
  }
  .col_footer {
    display: flex;
    justify-content: space-between;
    margin: 22px 0 0;

    @include sp {
      align-items: center;
      margin: 16px auto 0;
      width: 260px;
    }
  }
  .col_cat {
    display: flex;
    justify-content: center;
    padding: 2px 0 3px;
    width: 100px;
    border-radius: 6px;
    background-color: $color_17;

    @include sp {
      padding: 1px 0 3px;
      border-radius: 4px;
    }
  }
  .col_date {
    @include sp {
    }
  }
  .btn_col {
    margin: 40px auto 0;
    width: 336px;
    height: 60px;

    @include sp {
      margin: 30px auto 0;
      width: 280px;
      height: 50px;
    }
  }
}



/* =============================================== */
/* スライダーの設定
/* =============================================== */
.slick_dots_02 {
  position: inherit;
  margin: 13px 0 0;
  text-align: center;

  @include sp {
    margin: 2px 0 0;
  }
  li {
    display: inline-block;
  }
  button {
    /*ドットのデザイン*/
    display: block;
    margin: 0 20px 0 0;
    padding: 0;
    width: 10px;
    height: 10px;
    outline: none;
    border: 0;
    border-radius: 50%;
    background: $color_6;
    font-size: 0;
    cursor: pointer;
  }
  .slick-active button {
    /*ドットが選択された際の色変化*/
    background: $color_1;
  }
}
.slide-arrow {
  position: absolute;
  width: 52px;
  height: 53px;
  border: none;
  background-color: inherit;
  background-repeat: no-repeat;
  cursor: pointer;

  @include sp {
    top: 38%;
    width: 39px;
    height: 39px;
  }
}
.prev-arrow,
.next-arrow {
  @include pc {
    transition: all .3s;
  }
}
.prev-arrow {
  top: 43%;
  left: -75px;
  background-image: url(#{$img_dir}/common/slider-arw-prev.svg);
  transform: translate(0, -50%);

  @include sp {
    top: 38%;
    left: 10px;
    z-index: 10;
    background-image: url(#{$img_dir}/common/sp/slider-arw-prev.svg);
    transform: translate(0, 0);
  }
  &:hover {
    @include pc_tab {
      left: -80px;
      background-image: url(#{$img_dir}/common/slider-arw-prev-hover.svg);
    }
  }
}
.next-arrow {
  top: 43%;
  right: -75px;
  background-image: url(#{$img_dir}/common/slider-arw-next.svg);
  transform: translate(0, -50%);

  @include sp {
    top: 43%;
    right: 10px;
    z-index: 10;
    background-image: url(#{$img_dir}/common/sp/slider-arw-next.svg);
  }
  &:hover {
    @include pc_tab {
      right: -80px;
      background-image: url(#{$img_dir}/common/slider-arw-next-hover.svg);
      transition: all .3s;
    }
  }
}


// 20231108追加
.info_item_note {
  margin: 5px 0 0;
}

.sec_02 {
  .info_item {
    height: fit-content;
    &::before {
      height: 100%;
    }
  }
}
